<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4> Check-in/out</h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard</router-link>
                                    \ Check-in/out
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">

                                <v-col cols="12" md="3">
                                    <v-select
                                        outlined
                                        dense
                                        label="Attendance type"
                                        :items="types"
                                        v-model="search.type"
                                        item-text="name"
                                        item-value="value"
                                        v-on:keyup.enter="searchAttendanceReports()"
                                        clearable
                                    ></v-select>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-menu
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        min-width="auto"
                                        offset-y
                                        transition="scale-transition"
                                        v-model="menu"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            append-icon="mdi-calendar"
                                            clearable
                                            dense
                                            label="From"
                                            outlined
                                            readonly
                                            v-on:keyup.enter="searchAttendanceReports"
                                            v-bind="attrs"
                                            v-model="search.entry_date"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        @input="menu = false"
                                        v-model="search.entry_date"
                                    ></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-menu
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        min-width="auto"
                                        offset-y
                                        transition="scale-transition"
                                        v-model="menu1"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            append-icon="mdi-calendar"
                                            clearable
                                            dense
                                            label="To"
                                            outlined
                                            readonly
                                            v-on:keyup.enter="searchAttendanceReports"
                                            v-bind="attrs"
                                            v-model="search.exit_date"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        @input="menu1 = false"
                                        v-model="search.exit_date"
                                    ></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" md="3" class="text-right">
                                    <v-btn
                                      :loading="loading"
                                      @click.prevent="searchAttendanceReports()"
                                      class="btn btn-primary w-35"
                                      dark
                                    >
                                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                    Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>

                        <div class="">
                            <table class="table">
                                <thead>
                                <tr class="px-3">
                                    <th class="px-3 wrap-column">Type</th>
                                    <th class="px-3 wrap-column">Check-in time</th>
                                    <th class="px-3 wrap-column">Check-out time</th>
                                    <th class="px-3 wrap-column">Device</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-show="generalAttendances.length > 0"
                                    v-for="(item, index) in generalAttendances"
                                    :key="index"
                                >

                                    <td class="px-3 wrap-column">
                                        {{ item.type_formatted }}
                                    </td>
                                    <td class="px-3 wrap-column">
                                    {{ item.entry_timestamp ? item.entry_timestamp : '-' }}
                                    </td>
                                    <td class="px-3 wrap-column">
                                    {{ item.exit_timestamp ? item.exit_timestamp : '-' }}
                                    </td>
                                    <td class="px-3 wrap-column">
                                        <div>
                                            {{ item.device_user_id ? item.device_user_id : '-' }}
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="generalAttendances.length == 0">
                                    <td class="text-center" colspan="7">Data not available</td>
                                </tr>
                                </tbody>
                            </table>
                            <b-pagination
                                v-show="generalAttendances.length > 0"
                                @input="searchAttendanceReports"
                                class="pull-right mt-7"
                                v-model="page"
                                :total-rows="total"
                                :per-page="perPage"
                                first-number
                                last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </v-app>
</template>

<script>
import GeneralAttendanceService from "@/core/services/general-attendance/GeneralAttendanceService";

const generalAttendance = new GeneralAttendanceService()

export default {
    name:"GeneralAttendance",
    data() {
        return {
            loading: false,
            menu: false,
            menu1: false,
            userInfo: {
              rfid_id: '',
              is_rfid_expiry: false,
              rfid_expiry_date: '',
            },
            today: new Date().toISOString().substr(0, 10),
            search:{
                entry_date: '',
                exit_date: '',
                type: '',
            },
            generalAttendances: [],
            page: null,
            perPage: null,
            total: null,
          types:[
            {
              name: 'General attendance',
              value: 'attendance_general'
            },
            {
              name: 'Bus attendance',
              value: 'attendance_bus'
            },
            {
              name: 'Lunch attendance',
              value: 'attendance_lunch'
            }
          ],
        }
    },
    methods: {

        searchAttendanceReports(){
            this.loading = true;
            generalAttendance
            .paginate(this.search, this.page)
            .then((res) => {
                this.generalAttendances = res.data.data;
                this.page = res.data.meta.current_page;
                this.total = res.data.meta.total;
                this.perPage = res.data.meta.per_page;
                this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
            });
        },


    },
}
</script>

<style scoped>
    .wrap-column{
        max-width: 270px !important;
        white-space: pre-wrap;
    }
</style>
