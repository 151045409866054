import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class GeneralAttendanceService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student/general-attendance`;
    }

    paginate(data={}, index = null){
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
}
